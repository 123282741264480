const INTERACTION_TYPE_MORTGAGE = 'mortgage';
const INTERACTION_TYPE_UPGRADE = 'upgrade';
const INTERACTION_TYPE_DOWNGRADE = 'downgrade';
const INTERACTION_TYPE_TRADE = 'trade';

export {
    INTERACTION_TYPE_MORTGAGE,
    INTERACTION_TYPE_UPGRADE,
    INTERACTION_TYPE_DOWNGRADE,
    INTERACTION_TYPE_TRADE
};