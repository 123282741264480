/**
 * All in game listeners in one place
 *
 * @param gameId
 * @param game
 * @param setGame
 * @param setOpenOfferPurchaseModal
 * @param setPurchasableProperty
 * @param setTrade
 * @param setShowAuctionModal
 * @param setCard
 * @param setPaid
 * @param setBankruptcy
 * @param setWinner
 * @param setUpgradable
 * @param setDice
 * @constructor
 */
export const GameListeners = (gameId, game, setGame, setOpenOfferPurchaseModal, setPurchasableProperty, setTrade, setShowAuctionModal, setCard, setPaid, setBankruptcy, setWinner, setUpgradable, setDice) => {
    // gets the initial game on every load. useful for when refreshed
    if (!game || game.length <= 0) {
        window.socket.emit('get_game', gameId, (responses) => {
            setGame(responses.game);
        });
    }

    // listen to game fetch
    window.socket.on("get_game", (game) => {
        setGame(game);
    });

    // listen to offer purchase
    window.socket.on("offer_purchase", (property) => {
        setOpenOfferPurchaseModal(true);
        setPurchasableProperty(property);
    });

    // listen to trade property
    window.socket.on("get_trade_offer", (trade) => {
        setTrade(trade);
    });

    // listen to auction started event
    window.socket.on("auction_started", (responses) => {
        setShowAuctionModal(true);
    });

    // listen to announce auction winner event
    window.socket.on("announce_auction_winner", (responses) => {
        setShowAuctionModal(false);
    });

    // listen to player dice roll event
    window.socket.on("player_dice_rolled", (responses) => {
        setDice({...responses, open: true, self: false});
    });

    // listen to payment paid event
    window.socket.on("payment_paid", (responses) => {
        setPaid({message: responses.message, open: true});

        const timer = setTimeout(() => {
            setPaid({open: false});
        }, 4500);
        return () => clearTimeout(timer);
    });

    // listen to bankruptcy event
    window.socket.on("player_bankrupt", (responses) => {
        setBankruptcy({message: responses.message, open: true});

        const timer = setTimeout(() => {
            setBankruptcy({open: false});
        }, 4500);
        return () => clearTimeout(timer);
    });

    // listen to announce auction winner event
    window.socket.on("show_card", (responses) => {
        setCard({card: responses.card, open: true});

        const timer = setTimeout(() => {
            setCard({open: false});
        }, 4500);
        return () => clearTimeout(timer);
    });

    // listen to upgradable property event
    window.socket.on("can_upgrade_property", (responses) => {
        setUpgradable({property: responses.property, color: responses.color, open: true});

        const timer = setTimeout(() => {
            setUpgradable({open: false});
        }, 4500);
        return () => clearTimeout(timer);
    });

    // listen to winner announcement
    window.socket.on("announce_winner", (responses) => {
        setWinner({message: responses.message, open: true});
    });
};