import {toast} from 'react-toastify';
import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {nanoid} from 'nanoid';
import Button from '../components/buttons/Button';

/**
 * Lobby page
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Lobby() {

    // states
    const [game, setGame] = useState([]);

    let {gameId} = useParams();
    const navigate = useNavigate();

    // join a game for the given gameId
    const startGame = () => {
        window.socket.emit("start_game", gameId, (responses) => {
            if (!responses.status) {
                toast.error(responses.message);
                return;
            }
            toast.success(responses.message);
            navigate(`/game/${gameId}`, {state: {tiles: responses.tiles}});
        });
    };

    // leave the game
    const leaveGame = () => {
        window.socket.emit("leave_game", gameId, (responses) => {
            toast.success(responses.message);
            navigate('/');
        });
    };

    // use effect
    useEffect(() => {
        // get the game
        window.socket.emit('get_game', gameId, (responses) => {
            setGame(responses.game);
        });

        // listen to player joined
        window.socket.on("player_joined", (responses) => {
            toast.info(`${responses.player.name} has joined the game`);
            setGame(responses.game);
        });

        // listen to player leave
        window.socket.on("player_left", (responses) => {
            toast.warn(`${responses.player.name} Left the game`);
            setGame(responses.game);
        });

        // listen to game initialization
        window.socket.on("init_game", (responses) => {
            navigate(`/game/${gameId}`, {state: {tiles: responses.tiles}});
        });

        // listen to game abandoned
        window.socket.on('game_abandoned', () => {
            toast.error('The game was abandoned by the owner!');
            navigate('/');
        });
    }, []);

    return (
        <div className="flex flex-col gap-4 items-center justify-center mt-12 relative">
            <h1 className="font-bold text-2xl">Waiting for players to join...</h1>
            <span className="text-gray-500">Game ID: {gameId}</span>
            <ul>
                {
                    game?.players?.map((player) => (
                        <li key={nanoid()}
                            className="flex flex-row gap-1 items-center justify-center text-gray-800 text-sm">
                            <div className="w-3 h-3 rounded-full" style={{backgroundColor: player.color}}/>
                            <div>{player.name}</div>
                        </li>
                    ))
                }
            </ul>
            {
                window.socket.player_id === game.creator ?
                    <Button
                        label="Start Game"
                        onClick={startGame}
                    /> : null
            }
            <Button
                type="danger"
                label="Leave Game"
                onClick={leaveGame}
                className="-mt-2"
            />
        </div>
    );
}
