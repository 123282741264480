import {createBrowserRouter} from 'react-router-dom';
import Home from './pages/Home';
import Lobby from './pages/Lobby';
import Game from './pages/Game';

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/lobby/:gameId",
        element: <Lobby/>,
    },
    {
        path: "/game/:gameId",
        element: <Game/>,
    },
]);