import {motion} from 'framer-motion';
import Backdrop from './Backdrop';
import Button from '../buttons/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHome} from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from 'react-router-dom';

/**
 * Winner Modal Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function WinnerModal(props) {

    // destructured props
    const {winner} = props;

    // react hook
    const navigate = useNavigate();

    return (
        <Backdrop onClick={() => null}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="flex items-center justify-center z-50 max-w-xs"
                initial={{scale: 0, rotate: 10}}
                animate={{scale: 1, rotate: 0}}
                transition={{
                    type: 'spring',
                    stiffness: 1000,
                    damping: 20
                }}
            >

                <div className="relative">
                    <div
                        className="tile bg-white rounded-md p-6 flex flex-col items-center justify-between text-light-gray text-center gap-4 relative z-20 shadow-md">

                        <img src="/images/icons/winner.png" alt="card" className="w-32 h-32"/>

                        <span className="text-secondary text-2xl">{winner?.message}</span>

                        <Button
                            label={<FontAwesomeIcon icon={faHome}/>}
                            onClick={() => navigate('/')}
                        />

                    </div>
                </div>

            </motion.div>
        </Backdrop>
    );
};