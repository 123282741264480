import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSackDollar} from '@fortawesome/free-solid-svg-icons';

/**
 * Income Tax Tile Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function IncomeTaxTile(props) {

    // destructured props
    const {tile, renderPlayersPositions} = props;

    return (
        <div className="relative w-24 h-24">
            <div
                className="tile bg-gradient-to-r from-purple-500 to-purple-800 bg-white px-4 py-2 rounded-md flex flex-col items-center justify-between text-xs text-white font-bold text-center gap-1 w-24 h-24 relative z-20 shadow-md">
                <span>Pay MVR {tile.payable_amount}</span>
                <FontAwesomeIcon icon={faSackDollar} fontSize={20}/>
                <span className="text-[10px] leading-none font-normal">{tile.name}</span>
            </div>
            {renderPlayersPositions}
        </div>
    );
}
