import {motion} from "framer-motion";
import DiceBackdrop from './DiceBackdrop';
import DiceFaceOne from '../dice/DiceFaceOne';
import DiceFaceTwo from '../dice/DiceFaceTwo';
import DiceFaceThree from '../dice/DiceFaceThree';
import DiceFaceFour from '../dice/DiceFaceFour';
import DiceFaceFive from '../dice/DiceFaceFive';
import DiceFaceSix from '../dice/DiceFaceSix';

/**
 * Dice Modal Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function DiceModal(props) {

    // destructured props
    const {onAnimationComplete, dice} = props;

    // show dice
    const ShowDice = ({dice_number}) => {
        switch (dice[dice_number]) {
            case 1: {
                return <DiceFaceOne/>;
            }
            case 2: {
                return <DiceFaceTwo/>;
            }
            case 3: {
                return <DiceFaceThree/>;
            }
            case 4: {
                return <DiceFaceFour/>;
            }
            case 5: {
                return <DiceFaceFive/>;
            }
            case 6: {
                return <DiceFaceSix/>;
            }
            default: {
                return null;
            }
        }
    };

    return (
        <DiceBackdrop onClick={() => null}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="flex flex-col items-center text-center justify-center z-60 max-w-xs"
                initial={{scale: 0}}
                animate={{scale: 1}}
                transition={{
                    type: "spring",
                    stiffness: 1000,
                    damping: 20,
                    delayChildren: 1
                }}
            >


                <div className="flex flex-row gap-3">

                    <motion.div
                        animate={{rotate: 360}}
                        transition={{
                            duration: 3.5,
                            repeatType: 'reverse'
                        }}
                    >
                        <ShowDice dice_number="dice_1"/>
                    </motion.div>

                    <motion.div
                        animate={{rotate: 360}}
                        transition={{
                            duration: 3.5,
                            repeatType: 'reverse'
                        }}
                        onAnimationComplete={onAnimationComplete}
                    >
                        <ShowDice dice_number="dice_2"/>
                    </motion.div>

                </div>

                {
                    !dice.self &&
                    <motion.div
                        className="mt-8"
                        initial={{scale: 0}}
                        animate={{scale: 1}}
                        transition={{
                            type: "spring",
                            stiffness: 1000,
                            damping: 10
                        }}
                    >
                        <p className="text-white text-2xl">{dice.player.name} is rolling</p>
                    </motion.div>
                }

                {
                    dice.isDouble &&
                    <motion.div
                        className="mt-8"
                        initial={{scale: 0}}
                        animate={{scale: 1}}
                        transition={{
                            type: "spring",
                            stiffness: 1000,
                            damping: 10
                        }}
                    >
                        <p className="text-white text-4xl">{dice.self ? 'You' : dice.player.name} just rolled a
                            Double</p>
                    </motion.div>
                }

            </motion.div>
        </DiceBackdrop>
    );
};