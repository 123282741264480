import {useLocation, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import PurchasePropertyModal from '../components/modal/PurchasePropertyModal';
import JailOptionsModal from '../components/modal/JailOptionsModal';
import {
    INTERACTION_TYPE_DOWNGRADE,
    INTERACTION_TYPE_MORTGAGE,
    INTERACTION_TYPE_TRADE,
    INTERACTION_TYPE_UPGRADE
} from '../constants/InteractionConstants';
import TradeModal from '../components/modal/TradeModal';
import {TRADE_PLAYER_DEALER, TRADE_PLAYER_TRADER} from '../constants/TradeConstants';
import AuctionModal from '../components/modal/AuctionModal';
import Tiles from '../components/tiles/Tiles';
import PlayerContainer from '../components/player/PlayerContainer';
import Player from '../components/player/Player';
import {AnimatePresence, motion} from 'framer-motion';
import PlayerActionButton from '../components/buttons/PlayerActionButton';
import PlayerActions from '../components/buttons/PlayerActions';
import DiceModal from '../components/modal/DiceModal';
import {gameCurrentPlayerIsCurrentPlayer} from '../helpers/GameHelpers';
import {GameListeners} from '../listerners/GameListeners.js';
import EventContainer from '../components/event/EventContainer';
import Event from '../components/event/Event';
import CardModal from '../components/modal/CardModal';
import PayableModal from '../components/modal/PayableModal';
import PaidModal from '../components/modal/PaidModal';
import PlayerBankruptModal from '../components/modal/PlayerBankruptModal';
import WinnerModal from '../components/modal/WinnerModal';
import Button from '../components/buttons/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCog} from '@fortawesome/free-solid-svg-icons';
import SettingsModal from '../components/modal/SettingsModal';
import UpgradableModal from '../components/modal/UpgradableModal';
import {toast} from 'react-toastify';

/**
 * Game page
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Game() {

    // states
    const [game, setGame] = useState([]);
    const [openOfferPurchaseModal, setOpenOfferPurchaseModal] = useState(false);
    const [purchasableProperty, setPurchasableProperty] = useState(false);
    const [showJailOptionsModal, setShowJailOptionsModal] = useState(false);
    const [interacting, setInteracting] = useState({state: false, interaction: INTERACTION_TYPE_MORTGAGE});
    const [trade, setTrade] = useState({
        [TRADE_PLAYER_TRADER]: {
            playerObject: 0,
            properties: [],
            amount: 0
        },
        [TRADE_PLAYER_DEALER]: {
            playerObject: 0,
            properties: [],
            amount: 0
        },
        showModal: false,
        type: null
    });
    const [showAuctionModal, setShowAuctionModal] = useState(false);
    const [dice, setDice] = useState({open: false, self: true});
    const [card, setCard] = useState({open: false});
    const [showPayableModal, setShowPayableModal] = useState(false);
    const [paid, setPaid] = useState({open: false});
    const [bankruptcy, setBankruptcy] = useState({open: false});
    const [winner, setWinner] = useState({open: false});
    const [openSettingsModal, setOpenSettingsModal] = useState(false);
    const [upgradeable, setUpgradable] = useState({open: false});

    let {gameId} = useParams();
    const {state} = useLocation();

    // roll the dice
    const rollDice = (movePlayer = false, inJail = false) => {
        if (inJail) {
            setShowJailOptionsModal(false);
        }
        window.socket.emit("throw_dice", gameId, movePlayer, (responses) => {
            // if its only dice roll, then do not move the player
            if (!movePlayer) {
                setDice({...responses, open: true, self: true});
                return;
            }
            setDice({open: false});
            setShowJailOptionsModal(false);
        });
    };

    const onDiceAnimationEndHandler = () => {
        if (dice.self) {
            rollDice(true);
            return;
        }
        setDice({...dice, open: false})
    }

    // pay for jail
    const payJailFee = () => {
        window.socket.emit("pay_jail", gameId, (responses) => {
            setShowJailOptionsModal(false);
        });
    };

    // use the get out of jail card
    const useGetOutOfJailCard = () => {
        window.socket.emit("use_get_out_of_jail_card", gameId, (responses) => {
            setShowJailOptionsModal(false);
        });
    };

    // mortgage property
    const mortgageProperty = (property_id) => {
        window.socket.emit("mortgage_property", gameId, property_id);
    };

    // un-mortgage property
    const unMortgageProperty = (property_id) => {
        window.socket.emit("un_mortgage_property", gameId, property_id);
    };

    // upgrade property
    const upgradeProperty = (property_id) => {
        window.socket.emit("upgrade_property", gameId, property_id);
    };

    // downgrade property
    const downgradeProperty = (property_id) => {
        window.socket.emit("downgrade_property", gameId, property_id);
    };

    // make trade offer with player
    const makeTradeOffer = () => {
        window.socket.emit("make_trade_offer", gameId, trade, (responses) => {
            setTrade({...trade, showModal: false});
            setInteracting({...interacting, state: false});
        });
    };

    // confirm the trade offer
    const confirmTrade = () => {
        window.socket.emit("confirm_trade", gameId, trade, (responses) => {
            setTrade({...trade, showModal: false});
            setInteracting({...interacting, state: false});
        });
    };

    // trade properties with player
    const rejectTradeOffer = () => {
        window.socket.emit("reject_trade_offer", gameId, trade, (responses) => {
            setTrade({...trade, showModal: false});
        });
    };

    // end turn
    const endTurn = () => {
        // set interacting to false when player ends their turn
        if (interacting.state) {
            setInteracting({...interacting, state: false});
        }

        // if player has any pending payments, show the modal
        if (game?.currentPlayer?.payable?.amount) {
            setShowPayableModal(true);
            return;
        }
        window.socket.emit("end_player_turn", gameId, (responses) => {
            //
        });
    };

    // purchase the property
    const purchaseProperty = () => {
        window.socket.emit("purchase_property", gameId, purchasableProperty.id);
        setOpenOfferPurchaseModal(false);
    };

    // start property auction
    const startAuction = (property_id) => {
        window.socket.emit('start_auction', gameId, property_id, () => {
            setOpenOfferPurchaseModal(false);
        });
    };

    // bid amount for the auction
    const bidAuction = (amount) => {
        window.socket.emit('bid_auction', gameId, amount, () => {
            //
        });
    };

    // pay any payable amount
    const payPayableAmount = () => {
        window.socket.emit('pay_payable', gameId, () => {
            setShowPayableModal(false);
        });
    };

    // handle property click function
    const handlePropertyClick = (property) => {
        // if player is mortgaging
        if (interacting.interaction === INTERACTION_TYPE_MORTGAGE) {
            if (!property.isMortgaged) {
                mortgageProperty(property.id);
            }
            if (property.isMortgaged) {
                unMortgageProperty(property.id);
            }
        }
        // if player is upgrading
        if (interacting.interaction === INTERACTION_TYPE_UPGRADE) {
            upgradeProperty(property.id);
        }
        // if player is downgrading
        if (interacting.interaction === INTERACTION_TYPE_DOWNGRADE) {
            downgradeProperty(property.id);
        }
    };

    // handle what happens when we click a player
    const handlePlayerClick = (player) => {
        if (interacting.interaction === INTERACTION_TYPE_TRADE) {
            setTraderAndDealer(player);
        }
    };

    // set the trader and dealer
    const setTraderAndDealer = (player) => {
        setTrade({
            trader: {
                playerObject: game.currentPlayer,
                properties: [],
                amount: 0.00
            },
            dealer: {
                playerObject: player,
                properties: [],
                amount: 0.00
            },
            showModal: true
        });
    };

    // set the player interaction
    const handleInteractionClick = (interaction) => {
        let interact = true;

        // if interaction is set, and it's the same as the new interaction, then set it to false. otherwise, continue interaction
        // this is useful when multiple interactions exists and each interaction is pressed one after the other
        // e.g. pressing mortgage and upgrade afterwards will continue the interaction. however, if mortgage is pressed twice, it will stop interaction
        if (interacting.interaction === interaction && interacting.state) {
            interact = false;
        }

        setInteracting({state: interact, interaction: interaction});

        // depending on the interaction type, show toast message
        switch (interaction) {
            case INTERACTION_TYPE_MORTGAGE: {
                if (interact) {
                    toast.info("Select a property to Mortgage");
                }
                break;
            }
            case INTERACTION_TYPE_UPGRADE: {
                if (interact) {
                    toast.info("Select a property to Upgrade");
                }
                break;
            }
            case INTERACTION_TYPE_DOWNGRADE: {
                if (interact) {
                    toast.info("Select a property to Downgrade");
                }
                break;
            }
            case INTERACTION_TYPE_TRADE: {
                if (interact) {
                    toast.info("Select a player to trade with");
                }
                break;
            }
            default: {
                return null;
            }
        }
    };

    // use effect to listen to get_game event
    useEffect(() => {
        GameListeners(gameId, game, setGame, setOpenOfferPurchaseModal, setPurchasableProperty, setTrade, setShowAuctionModal, setCard, setPaid, setBankruptcy, setWinner, setUpgradable, setDice);
    }, []);

    return (
        <div className="bg-gray-50 min-w-full h-screen flex flex-row pl-4 pt-4 pb-4 relative">

            {/*settings cog button*/}
            <Button
                className="absolute bottom-4 right-4 !rounded-full !p-2 z-60"
                label={<FontAwesomeIcon icon={faCog} fontSize={16}/>}
                onClick={() => setOpenSettingsModal(true)}
            />

            <EventContainer>

                {
                    game?.logs?.sort((a, b) => (b.id - a.id)).map((event) => (
                        <Event
                            key={event.id}
                            event={event}
                        />
                    ))
                }

            </EventContainer>

            <div className="game-container p-4">

                {/*tiles*/}
                <Tiles
                    tiles={state?.tiles || []}
                    players={game.players || []}
                    interacting={interacting}
                    handlePropertyClick={(tile) => handlePropertyClick(tile)}
                />

            </div>

            <PlayerContainer>
                {
                    game?.players?.map((player, index) => (
                        <motion.div
                            key={player.id}
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            transition={{
                                delay: 1 * (index + 1)
                            }}
                        >
                            <Player
                                player={player}
                                onClick={() => handlePlayerClick(player)}
                                isCurrentPlayer={player.id === game.currentPlayer.id}
                                interacting={interacting.state && interacting.interaction === INTERACTION_TYPE_TRADE && player.id !== game.currentPlayer.id}
                            />
                        </motion.div>
                    ))
                }
            </PlayerContainer>

            {/*show the action panel if the player is the same as the socket player (browser player), if there is no offer on going and if the current play 'canPlay' is true*/}
            <PlayerActions
                visible={gameCurrentPlayerIsCurrentPlayer(game?.currentPlayer) && !game?.offerOnGoing && game?.currentPlayer?.canPlay}
            >

                <PlayerActionButton
                    onClick={() => rollDice()}
                    img="/images/icons/dices.png"
                    visible={game?.currentPlayer?.canThrowDice && !dice.open}
                />

                <PlayerActionButton
                    onClick={() => setShowJailOptionsModal(true)}
                    img="/images/icons/jail.png"
                    visible={game?.currentPlayer?.isInJail}
                />

                <PlayerActionButton
                    onClick={() => handleInteractionClick(INTERACTION_TYPE_MORTGAGE)}
                    img="/images/icons/keys.png"
                    visible={game?.currentPlayer?.properties?.length}
                />

                <PlayerActionButton
                    onClick={() => handleInteractionClick(INTERACTION_TYPE_TRADE)}
                    img="/images/icons/deal.png"
                    visible={true}
                />

                <PlayerActionButton
                    onClick={() => handleInteractionClick(INTERACTION_TYPE_UPGRADE)}
                    img="/images/icons/add-house.png"
                    visible={game?.currentPlayer?.properties?.length}
                />

                <PlayerActionButton
                    onClick={() => handleInteractionClick(INTERACTION_TYPE_DOWNGRADE)}
                    img="/images/icons/remove-house.png"
                    visible={game?.currentPlayer?.properties?.length}
                />

                <PlayerActionButton
                    onClick={endTurn}
                    img="/images/icons/end.png"
                    visible={!game?.currentPlayer?.canThrowDice && !game?.currentPlayer?.isInJail}
                />

            </PlayerActions>

            {/*open dice results modal*/}
            <AnimatePresence
                initial={false}
                mode="wait"
                onExitComplete={() => null}
            >
                {
                    dice.open &&
                    <DiceModal
                        handleClose={setDice}
                        onAnimationComplete={onDiceAnimationEndHandler}
                        dice={dice}
                    />
                }
            </AnimatePresence>

            {/*open purchase property modal*/}
            <AnimatePresence
                initial={false}
                mode="wait"
                onExitComplete={() => null}
            >
                {
                    openOfferPurchaseModal &&
                    <PurchasePropertyModal
                        purchase={purchaseProperty}
                        startAuction={(property_id) => startAuction(property_id)}
                        property={purchasableProperty}
                        gameObject={game}
                    />
                }
            </AnimatePresence>

            {/*open auction modal*/}
            <AnimatePresence
                initial={false}
                mode="wait"
                onExitComplete={() => null}
            >
                {
                    showAuctionModal &&
                    <AuctionModal
                        gameObject={game}
                        bidAuction={(amount) => bidAuction(amount)}
                    />
                }
            </AnimatePresence>

            {/*open trade modal*/}
            <AnimatePresence
                initial={false}
                mode="wait"
                onExitComplete={() => null}
            >
                {
                    trade.showModal &&
                    <TradeModal
                        trade={trade}
                        setTrade={setTrade}
                        gameObject={game}
                        makeTradeOffer={makeTradeOffer}
                        confirmTrade={confirmTrade}
                        rejectTradeOffer={rejectTradeOffer}
                    />
                }
            </AnimatePresence>

            {/*open jail options modal*/}
            <AnimatePresence
                initial={false}
                mode="wait"
                onExitComplete={() => null}
            >
                {
                    showJailOptionsModal &&
                    <JailOptionsModal
                        gameObject={game}
                        setOpen={setShowJailOptionsModal}
                        rollDouble={() => rollDice(false, true)}
                        payJailAmount={payJailFee}
                        useGetOutOfJailCard={useGetOutOfJailCard}
                    />
                }
            </AnimatePresence>

            {/*open card modal*/}
            <AnimatePresence
                initial={false}
                mode="wait"
                onExitComplete={() => null}
            >
                {
                    card.open &&
                    <CardModal
                        card={card}
                        setCard={setCard}
                    />
                }
            </AnimatePresence>

            {/*open raise money modal*/}
            <AnimatePresence
                initial={false}
                mode="wait"
                onExitComplete={() => null}
            >
                {
                    showPayableModal &&
                    <PayableModal
                        gameObject={game}
                        setShowPayableModal={setShowPayableModal}
                        payPayableAmount={payPayableAmount}
                    />
                }
            </AnimatePresence>

            {/*open payment/paid modal*/}
            <AnimatePresence
                initial={false}
                mode="wait"
                onExitComplete={() => null}
            >
                {
                    paid.open &&
                    <PaidModal
                        paid={paid}
                        setPaid={setPaid}
                    />
                }
            </AnimatePresence>

            {/*open player bankruptcy modal*/}
            <AnimatePresence
                initial={false}
                mode="wait"
                onExitComplete={() => null}
            >
                {
                    bankruptcy.open &&
                    <PlayerBankruptModal
                        bankruptcy={bankruptcy}
                        setBankruptcy={setBankruptcy}
                    />
                }
            </AnimatePresence>

            {/*open winner modal*/}
            <AnimatePresence
                initial={false}
                mode="wait"
                onExitComplete={() => null}
            >
                {
                    winner.open &&
                    <WinnerModal
                        winner={winner}
                    />
                }
            </AnimatePresence>

            {/*open upgradable property modal*/}
            <AnimatePresence
                initial={false}
                mode="wait"
                onExitComplete={() => null}
            >
                {
                    upgradeable.open &&
                    <UpgradableModal
                        color={upgradeable?.color}
                        setUpgradable={setUpgradable}
                    />
                }
            </AnimatePresence>

            {/*open settings modal*/}
            <AnimatePresence
                initial={false}
                mode="wait"
                onExitComplete={() => null}
            >
                {
                    openSettingsModal &&
                    <SettingsModal
                        setOpenSettingsModal={setOpenSettingsModal}
                        gameObject={game}
                    />
                }
            </AnimatePresence>

        </div>
    );
}
