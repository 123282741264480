import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDroplet, faLightbulb} from '@fortawesome/free-solid-svg-icons';
import {motion} from 'framer-motion';
import {currentPlayerIsPropertyOwner} from '../../helpers/GameHelpers';

/**
 * Util Tile Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function UtilTile(props) {

    // destructured props
    const {tile, onClick, renderPlayersPositions, interacting, property, isMortgaged} = props;

    // util icon
    const icon = () => tile.name === 'Electric' ? faLightbulb : faDroplet;

    return (
        <motion.div
            className={`relative w-24 h-24 cursor-pointer ${interacting && currentPlayerIsPropertyOwner(property?.owner) ? 'animate-pulse' : ''}`}
            onClick={onClick}
            whileHover={{scale: 1.2}}
            whileTap={{scale: 0.9}}
            transition={{type: "spring", stiffness: 400, damping: 10}}>
            <div
                className={`tile bg-white rounded-md px-4 py-2 flex flex-col items-center justify-between text-xs text-light-gray font-bold text-center gap-1 w-24 h-24 relative z-20 shadow-md ${isMortgaged ? '!bg-red-500 !text-white' : ''}`}>
                {!isMortgaged && <span>MVR {tile.price.purchase_amount}</span>}
                {isMortgaged && <span className="text-[12px] leading-none font-normal">MORTGAGED</span>}
                <FontAwesomeIcon icon={icon()} fontSize={20}/>
                <span className="text-[10px] leading-none font-normal">{tile.name}</span>
                <div className="w-3 h-3 bg-gray-200 absolute top-0 right-0 rounded-tr-md"
                     style={{backgroundColor: property?.owner?.color}}/>
            </div>
            {renderPlayersPositions}
        </motion.div>
    );
}
