import PlayerBubble from './PlayerBubble';

/**
 * Player Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Player(props) {

    // destructured props
    const {player, isCurrentPlayer, onClick, interacting = false} = props;

    return (
        <div
            className={`player bg-white rounded-tl-md rounded-bl-md bg-gray-500 ${!isCurrentPlayer && !player.isBankrupt ? 'cursor-pointer' : ''} relative`}
            onClick={!isCurrentPlayer && !player.isBankrupt ? onClick : null}>
            <div className="player-container p-2 flex flex-col gap-1 w-2/3 bg-white rounded-md relative">
                <div className="avatar">
                    <div className="avatar-image">
                        <img src="/images/samples/13.png" alt="avatar"
                             className={`h-16 rounded-md object-cover ${player.isBankrupt ? 'opacity-75' : ''}`}/>
                    </div>
                </div>
                <div className="flex flex-col">
                    <span
                        className={`font-bold text-sm ${player.isBankrupt ? 'text-light-gray' : 'text-primary'}`}>{player.name}</span>
                    <span
                        className={`font-bold text-sm ${player.isBankrupt ? 'text-light-gray' : 'text-primary'}`}>MVR {player.bank}</span>
                    {player.isBankrupt && <span
                        className="text-[12px] leading-none font-normal p-1 bg-red-500 text-white text-center self-start">BANKRUPT</span>}
                </div>
                {
                    interacting &&
                    <PlayerBubble
                        className="!absolute top-1 right-1 animate-ping"
                        color={player.color}
                    />
                }

            </div>
            <div
                className={`absolute top-0 left-0 h-full rounded-md w-2/3 ${isCurrentPlayer ? 'ml-1' : '-ml-1'} ${isCurrentPlayer ? 'animate-pulse' : ''}`}
                style={{backgroundColor: !player.isBankrupt ? player.color : '#D3D3D3'}}/>
        </div>
    );
}
