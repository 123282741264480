import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHandcuffs} from '@fortawesome/free-solid-svg-icons';

/**
 * Jail Tile Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function JailTile(props) {

    // destructured props
    const {tile, renderPlayersPositions} = props;

    return (
        <div
            className="tile bg-gradient-to-r from-orange-500 to-red-500 rounded-md p-4 flex items-center justify-center text-white w-24 h-24 relative shadow-md flex flex-col gap-2 text-xs text-center">
            <FontAwesomeIcon icon={faHandcuffs} fontSize={42}/>
            <span>{tile.name}</span>
            {renderPlayersPositions}
        </div>
    );
}
