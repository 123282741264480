import {motion} from 'framer-motion';
import Backdrop from './Backdrop';
import Button from '../buttons/Button';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';

/**
 * Settings Modal Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function SettingsModal(props) {

    // destructured props
    const {setOpenSettingsModal, gameObject} = props;

    // react router
    const navigate = useNavigate();

    // leave the game
    const leaveGame = () => {
        window.socket.emit("leave_game", gameObject.id, (responses) => {
            toast.success(responses.message);
            navigate('/');
        });
    };

    return (
        <Backdrop onClick={() => setOpenSettingsModal(false)}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="flex items-center justify-center z-50 max-w-xs"
                initial={{scale: 0, rotate: 10}}
                animate={{scale: 1, rotate: 0}}
                transition={{
                    type: 'spring',
                    stiffness: 1000,
                    damping: 20
                }}
            >

                <div className="relative">
                    <div
                        className="tile bg-white rounded-md p-6 flex flex-col items-center justify-between text-light-gray text-center gap-4 relative z-20 shadow-md">

                        <img src="/images/icons/monopoly.png" alt="card" className="w-16 h-16"/>

                        <div>

                            <Button
                                type="danger"
                                label="Leave Game"
                                onClick={leaveGame}
                            />

                        </div>

                    </div>
                </div>

            </motion.div>
        </Backdrop>
    );
};