import {motion} from "framer-motion";

/**
 * Modal Backdrop
 *
 * @returns {JSX.Element}
 * @constructor
 * @param props
 */
export default function Backdrop(props) {


    // destructured props
    const {children, onClick} = props;

    return (
        <motion.div
            onClick={onClick}
            className="fixed top-0 left-0 w-screen min-h-screen bg-gray-800 z-50 bg-opacity-50 backdrop-blur-sm flex items-center justify-center"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            {children}
        </motion.div>
    );
};