import {useState} from 'react';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import Button from '../components/buttons/Button';
import Input from '../components/inputs/Input';
import {AnimatePresence} from 'framer-motion';
import MyOngoingGamesModal from '../components/modal/MyOngoingGamesModal';

/**
 * Home Page
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Home() {

    // states
    const [name, setName] = useState(null);
    const [gameId, setGameId] = useState(null);
    const [myOngoingGames, setMyOngoingGames] = useState({open: false});
    const navigate = useNavigate();

    // create a new game
    const createGame = () => {
        if (!name) {
            toast.error('Preferred Name is required to proceed.');
            return;
        }
        window.socket.emit("create_game", name, (responses) => {
            if (!responses.status) {
                toast.error(responses.message);
                return;
            }
            navigate(`/lobby/${responses.game.id}`);
            toast.success(responses.message);
        });
    };

    // join a game for the given gameId
    const joinGame = () => {
        if (!gameId || !name) {
            toast.error('Preferred Name & Game ID are required to proceed.');
            return;
        }
        window.socket.emit("join_game", gameId, name, (responses) => {
            if (!responses.status) {
                toast.error(responses.message);
                return;
            }
            navigate(`/lobby/${responses.game.id}`);
            toast(responses.message, {
                type: responses.type
            });
        });
    };

    // shows the players ongoing games
    const showMyOngoingGames = () => {
        window.socket.emit("get_my_ongoing_games", (responses) => {
            if (responses?.games?.length <= 0) {
                toast.error("You do not have any games you can join");
                return;
            }
            setMyOngoingGames({games: responses.games, tiles: responses.tiles, open: true});
        });
    };

    return (
        <>
            <div className="flex flex-col gap-4 items-center justify-center mt-12">
                <img src="/images/logo.png" alt="Logo" className="w-32"/>
                <Input
                    placeholder="Preferred Name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                />
                <Input
                    placeholder="Game ID"
                    onChange={(e) => setGameId(e.target.value)}
                    value={gameId}
                />
                <Button
                    label="Join Game"
                    onClick={joinGame}
                />
                <Button
                    label="Create a Game"
                    onClick={createGame}
                />
                <button
                    className="text-primary underline text-xs"
                    onClick={showMyOngoingGames}>
                    Disconnected? Rejoin your game
                </button>
            </div>

            {/*open my ongoing games modal*/}
            <AnimatePresence
                initial={false}
                mode="wait"
                onExitComplete={() => null}
            >
                {
                    myOngoingGames.open &&
                    <MyOngoingGamesModal
                        myOngoingGames={myOngoingGames}
                        setMyOngoingGames={setMyOngoingGames}
                    />
                }
            </AnimatePresence>
        </>

    );
}
