const GAME_STATUS_IN_LOBBY = 'in_lobby';
const GAME_STATUS_STARTED = 'started';
const GAME_STATUS_FINISHED = 'finished';
const GAME_STATUS_ABANDONED = 'abandoned';

export {
    GAME_STATUS_IN_LOBBY,
    GAME_STATUS_STARTED,
    GAME_STATUS_FINISHED,
    GAME_STATUS_ABANDONED
};