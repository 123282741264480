import {motion} from 'framer-motion';
import Backdrop from './Backdrop';
import Button from '../buttons/Button';
import {playerCanPurchaseProperty} from '../../helpers/GameHelpers';
import PropertyDetailCard from '../property/PropertyDetailCard';

/**
 * Tile Detail Modal Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PurchasePropertyModal(props) {

    // destructured props
    const {purchase, startAuction, property, gameObject} = props;

    return (
        <Backdrop onClick={null}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="flex items-center justify-center z-50 max-w-xs"
                initial={{scale: 0, rotate: 10}}
                animate={{scale: 1, rotate: 0}}
                transition={{
                    type: 'spring',
                    stiffness: 1000,
                    damping: 20
                }}
            >

                <PropertyDetailCard property={property}>
                    <div className="flex flex-row justify-between gap-4">

                        <Button
                            label="Purchase"
                            style={{backgroundColor: property.color ? property.color : null}}
                            onClick={purchase}
                            disabled={!playerCanPurchaseProperty(gameObject, property?.price?.purchase_amount)}
                        />

                        <Button
                            label="Auction"
                            style={{backgroundColor: property.color ? property.color : null}}
                            onClick={() => startAuction(property?.id)}
                        />

                    </div>
                </PropertyDetailCard>

            </motion.div>
        </Backdrop>
    );
};