import {motion} from "framer-motion";
import Backdrop from "./Backdrop";
import PropertyDetailCard from '../property/PropertyDetailCard';

/**
 * Tile Detail Modal Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function TileDetailModal(props) {

    // destructured props
    const {handleClose, tile} = props;

    return (
        <Backdrop onClick={() => handleClose({...tile, open: false})}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="flex items-center justify-center z-50 max-w-xs"
                initial={{scale: 0, rotate: 10}}
                animate={{scale: 1, rotate: 0}}
                transition={{
                    type: "spring",
                    stiffness: 1000,
                    damping: 20
                }}
            >

                <PropertyDetailCard property={tile.tile}/>

            </motion.div>
        </Backdrop>
    );
};