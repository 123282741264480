import {AnimatePresence, motion} from 'framer-motion';

/**
 * End Turn Button Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PlayerActionButton(props) {

    // destructured props
    const {onClick, img = '', visible = false} = props;

    return (
        <AnimatePresence>
            {
                visible && (
                    <motion.div
                        className="relative w-24 h-24 cursor-pointer flex items-center justify-center"
                        onClick={onClick}
                        whileHover={{scale: 1.1}}
                        whileTap={{scale: 0.9}}
                        initial={{scale: 0}}
                        animate={{scale: 1, transition: {type: "spring", stiffness: 400, damping: 20}}}
                        exit={{scale: 0, transition: {type: "spring", stiffness: 400, damping: 20}}}
                        style={{display: visible ? 'flex' : 'none'}}
                        transition={{type: "spring", stiffness: 400, damping: 10}}>
                        <img src={img} alt="action_image" className="w-16 h-16"/>
                    </motion.div>
                )
            }
        </AnimatePresence>
    );
}