/**
 * Input Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Input(props) {

    // destructured props
    let {
        label = '',
        placeholder = 'sample text',
        type = 'text',
        onChange,
        value,
        disabled = false,
        className,
        required = false
    } = props;

    return (
        <div>
            {
                label &&
                <span className="text-primary self-start font-bold text-sm">{label}</span>
            }
            <div className="relative">
                {
                    required &&
                    <span className="text-red-500 font-bold text-md absolute top-0 right-0">*</span>
                }
                <input
                    disabled={disabled}
                    type={type}
                    className={`transition focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-offset-2 rounded-full text-sm bg-white w-full h-10 px-2 text-primary border border-gray-200 shadow-sm mt-1 ${className}`}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value === 0 ? 0 : value || ""}/>
            </div>

        </div>
    );
}