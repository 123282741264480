import {motion} from 'framer-motion';

/**
 * Event Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Event(props) {

    // destructured props
    const {event} = props;

    //  get the time
    const getTime = () => {
        const time = new Date(event.ts);
        if (time) {
            const hours = ('0' + time.getHours()).slice(-2);
            const minutes = ('0' + time.getMinutes()).slice(-2);
            const seconds = ('0' + time.getSeconds()).slice(-2);
            return `${hours}:${minutes}:${seconds}`;
        }
    };

    return (
        <motion.div
            className="event relative p-2 bg-gray-50 rounded-md"
            initial={{scale: 0}}
            animate={{scale: 1}}
            transition={{
                type: "spring",
                stiffness: 1000,
                damping: 20,
            }}
        >

            <div className="avatar flex flex-row gap-2">
                <div className="avatar-image">
                    <img src={event?.player ? "/images/samples/7.jpg" : "/images/icons/monopoly.png"} alt="avatar"
                         className="w-16 h-12 rounded-md object-cover"/>
                </div>
                <div className="user-content flex flex-col">
                    <div className="flex flex-row justify-between items-center">
                        <span className="font-semibold text-sm text-primary">{event?.player?.name || 'Monopoly'}</span>
                        <span className="text-xs text-secondary">{getTime()}</span>
                    </div>
                    <span
                        className="text-xs text-secondary">{event?.message}</span>
                </div>
            </div>

            {
                event?.player ?
                    <div className="w-3 h-3 absolute top-0 left-0 rounded-tl-md"
                         style={{backgroundColor: event?.player?.color}}/> : null
            }

        </motion.div>
    );
}
