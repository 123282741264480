const CARD_TYPE_GET_OUT_OF_JAIL = 'get_out_of_jail';

/**
 * Checks if the current player is in jail
 *
 * @param gameObject
 * @returns {boolean}
 */
export const currentPlayerIsInJail = (gameObject) => {
    return !!(gameObject.currentPlayer.id === window.socket.id && gameObject.currentPlayer.isInJail);
};

/**
 * Checks to see if the current player has get out of jail free card
 *
 * @param gameObject
 * @returns {boolean}
 */
export const currentPlayerHasGetOutOfJailCard = (gameObject) => {
    const get_out_of_jail_card = gameObject.currentPlayer.cards.find((card) => card.type === CARD_TYPE_GET_OUT_OF_JAIL);
    return !!get_out_of_jail_card;
};

/**
 * Can player purchase/afford the property
 *
 * @param gameObject
 * @param property_price
 * @returns {boolean}
 */
export const playerCanPurchaseProperty = (gameObject, property_price) => {
    return gameObject?.currentPlayer?.bank >= property_price;
};

/**
 * Get the property owner
 *
 * @param players
 * @param tile
 * @returns {*}
 */
export const getPropertyOwner = (players, tile) => {
    return players.find((player) => player.properties.find((property) => property.id === tile.id)?.id === tile.id);
};

/**
 * Checks if the games current player is the current player from the socket
 *
 * @returns {boolean}
 * @param currentPlayer
 */
export const gameCurrentPlayerIsCurrentPlayer = (currentPlayer) => {
    return currentPlayer?.id === window.socket.player_id;
};

/**
 * Checks if the socket player is the provided property owner
 *
 * @param propertyOwner
 * @returns {boolean}
 */
export const currentPlayerIsPropertyOwner = (propertyOwner) => {
    return propertyOwner?.id === window.socket.player_id;
};

/**
 * Find the property from all players
 *
 * @param players
 * @param tile
 * @returns {null}
 */
export const findPropertyFromPlayers = (players, tile) => {
    let _property = null;
    let _owner = null;
    players.map((player) => (
        player.properties.forEach((property) => {
            if (property.id === tile.id) {
                _property = property;
                _owner = player;
            }
        })
    ));
    return {property: _property, owner: _owner};
};

/**
 * Checks if the property is mortgaged
 *
 * @param players
 * @param tile
 * @returns {boolean}
 */
export const isPropertyMortgaged = (players, tile) => {
    const property = findPropertyFromPlayers(players, tile);
    return !!(property?.property && property?.property?.isMortgaged);
};

/**
 * Checks to see if the player has the card specified by type
 *
 * @param playerObject
 * @param card_type
 * @returns {boolean}
 */
export const playerHasCard = (playerObject, card_type) => {
    const card = playerObject.cards.find((card) => card.type === card_type);
    return !!card;
};