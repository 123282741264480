import Backdrop from './Backdrop';
import {motion} from 'framer-motion';
import Button from '../buttons/Button';
import {CARD_TYPE_GET_OUT_OF_JAIL} from '../../constants/CardConstants';
import {playerHasCard} from '../../helpers/GameHelpers';

/**
 * Jail Options Modal Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function JailOptionsModal(props) {

    //destructured props
    const {setOpen, gameObject, rollDouble, payJailAmount, useGetOutOfJailCard} = props;

    return (
        <Backdrop onClick={null}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6 z-50"
                initial={{scale: 0, rotate: 10}}
                animate={{scale: 1, rotate: 0}}
                transition={{
                    type: 'spring',
                    stiffness: 1000,
                    damping: 20
                }}
            >
                <div>
                    <div
                        className="mx-auto flex h-24 w-24 items-center justify-center rounded-full">
                        <img src="/images/prisoner.png"/>
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                        <h3
                            className="text-base font-semibold leading-6 text-primary">
                            You are in Jail!
                        </h3>
                        <div className="mt-2">
                            <p className="text-sm text-secondary">
                                You can either roll the dice to get doubles, use the "get out of jail" card (if you
                                have any) or pay a fine of MVR 50
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-col justify-center items-center items-start gap-4">

                    <Button
                        label={`Roll Double (${gameObject?.currentPlayer?.jailProp?.doubleCounter})`}
                        onClick={rollDouble}
                        disabled={gameObject?.currentPlayer?.jailProp?.doubleCounter <= 0}
                    />

                    <Button
                        label={`Pay MVR ${gameObject?.currentPlayer?.jailProp?.payable_amount}`}
                        onClick={payJailAmount}
                    />

                    <Button
                        label={`Use "Get out of Jail" card`}
                        onClick={useGetOutOfJailCard}
                        disabled={!playerHasCard(gameObject?.currentPlayer, CARD_TYPE_GET_OUT_OF_JAIL)}
                    />

                    <Button
                        label={`Close`}
                        type="danger"
                        onClick={() => setOpen(false)}
                    />
                </div>

            </motion.div>
        </Backdrop>
    );
}
