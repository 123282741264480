import {useEffect} from 'react';
import {toast} from 'react-toastify';

/**
 * Main Layout
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Layout(props) {

    // destructured props
    const {children} = props;

    // initial listeners registered on app load
    useEffect(() => {
        // listen to toast message
        window.socket.on("toast_message", (responses) => {
            toast(responses.message, {
                type: responses.type
            });
        });
    }, []);

    return (
        <div className="w-full min-h-screen flex flex-col items-center justify-center">

            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link href="https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap" rel="stylesheet"/>


            {children}

        </div>
    );
}
