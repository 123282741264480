import {motion} from 'framer-motion';
import Backdrop from './Backdrop';
import {CARD_TYPE_CHANCE} from '../../constants/CardConstants';

/**
 * Card Modal Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function CardModal(props) {

    // destructured props
    const {card, setCard} = props;

    const imageSource = () => card?.card?.card_type === CARD_TYPE_CHANCE ? "/images/icons/chance.png" : "/images/icons/chest.png";

    return (
        <Backdrop onClick={() => setCard({open: false})}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="flex items-center justify-center z-50 max-w-xs"
                initial={{scale: 0, rotate: 10}}
                animate={{scale: 1, rotate: 0}}
                transition={{
                    type: 'spring',
                    stiffness: 1000,
                    damping: 20
                }}
            >

                <div className="relative">
                    <div
                        className="tile bg-white rounded-md p-6 flex flex-col items-center justify-between text-light-gray text-center gap-4 relative z-20 shadow-md">

                        <img src={imageSource()} alt="card" className="w-16 h-16"/>

                        <span className="text-secondary text-2xl">{card?.card?.description}</span>

                    </div>
                </div>

            </motion.div>
        </Backdrop>
    );
};