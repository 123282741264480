import './bootstrap';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/style.css';
import {RouterProvider,} from "react-router-dom";
import {router} from './router';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './components/layout/Layout';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Layout>
        <ToastContainer
            position="top-center"
            autoClose={1500}
            limit={3}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />
        <RouterProvider router={router}/>
    </Layout>
);