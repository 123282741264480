import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TILE_TYPE_PROPERTY, TILE_TYPE_STATION, TILE_TYPE_UTIL} from '../../constants/TileConstants';
import {faDroplet, faHouse, faLightbulb, faTrain} from '@fortawesome/free-solid-svg-icons';

/**
 * Property card Detail Modal Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PropertyDetailCard(props) {

    // destructured props
    const {property, children} = props;

    // icon
    const icon = () => {
        if (property.type === TILE_TYPE_PROPERTY) return faHouse;
        if (property.type === TILE_TYPE_UTIL) return property.name === 'Electric' ? faLightbulb : faDroplet;
        if (property.type === TILE_TYPE_STATION) return faTrain;
    };

    // stages text
    const stagesText = (stage) => {
        if (property.type === TILE_TYPE_STATION) return `If ${stage.stage} is owned`;
        if (property.type === TILE_TYPE_PROPERTY) {
            if (stage.stage === 5) return `With Hotel`;
            else return `With ${stage.stage} House`;
        }
    };

    // text for stage costs
    const stageCostText = (stage = 0) => {
        if ([TILE_TYPE_STATION, TILE_TYPE_UTIL].includes(property.type)) return null;
        if (stage === 0) return `Each House costs MVR ${property.price.stages[stage].purchase_amount}`;
        if (stage === 4) return `Hotel costs MVR ${property.price.stages[stage].purchase_amount}`;
    };

    // util text
    const utilText = () => {
        if (property.type === TILE_TYPE_UTIL) return `If one Utility is owned, rent is 4 times the amount shown on the dice. If both Utilities are owned, rent is 10 times the amount shown on the dice`;
    };

    return (
        <div className="relative">
            <div
                className="tile bg-white rounded-md p-6 flex flex-col items-center justify-between text-light-gray text-center gap-4 relative z-20 shadow-md">
                <FontAwesomeIcon icon={icon()} fontSize={32} style={{color: property?.color}}/>

                <span className="text-xl">{property.name}</span>

                <div className="flex flex-col gap-2 text-sm">
                    <span>Rent MVR {property.price.rent_amount}</span>
                </div>

                <div className="flex flex-col gap-3">

                    {
                        property.price.stages.map((stage, index) => (
                            <div key={`stages_${index}_${stage.id}`}
                                 className="flex flex-row items-center justify-between gap-4 text-xs">
                                <span>{stagesText(stage)}</span>
                                <span className="font-bold">MVR {stage.rent_amount}</span>
                            </div>
                        ))
                    }

                    <div className="flex flex-col text-xs font-bold">
                        <span>Mortgage Value MVR {property.price.mortgage_value}</span>
                        <span>{stageCostText(0)}</span>
                        <span>{stageCostText(4)}</span>
                        <span className="mt-2">{utilText()}</span>
                    </div>

                </div>

                {children}

            </div>
            <div className="absolute top-0 left-0 h-full rounded-md mt-1 w-full z-10"
                 style={{backgroundColor: property?.color}}/>

        </div>
    );
};