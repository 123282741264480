const TRADE_TYPE_OFFER = 'offer';
const TRADE_TYPE_COUNTER_OFFER = 'counter_offer';
const TRADE_PLAYER_TRADER = 'trader';
const TRADE_PLAYER_DEALER = 'dealer';

export {
    TRADE_TYPE_OFFER,
    TRADE_TYPE_COUNTER_OFFER,
    TRADE_PLAYER_TRADER,
    TRADE_PLAYER_DEALER
};