import {motion} from 'framer-motion';

/**
 * Button Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Button(props) {

    // destructured props
    let {label, onClick, style, type = 'primary', disabled = false, className} = props;

    // button types class
    const button_type = () => {
        type = disabled ? 'disabled' : type;
        switch (type) {
            case 'primary': {
                return 'bg-blue-500 text-white';
            }
            case 'danger': {
                return 'bg-gradient-to-r from-red-500 to-red-700 text-white';
            }
            case 'warning': {
                return 'bg-gradient-to-r from-yellow-500 to-yellow-700 text-white';
            }
            case 'disabled': {
                return 'bg-gray-600 text-light-gray';
            }
            default: {
                return null;
            }
        }
    };

    return (
        <motion.div
            onClick={!disabled ? onClick : null}
            whileHover={{scale: !disabled ? 1.2 : 1}}
            whileTap={{scale: !disabled ? 0.9 : 1}}
            transition={{type: 'spring', stiffness: 400, damping: 10}}
            style={style}
            className={`flex items-center justify-center px-3 py-2 rounded-md cursor-pointer text-md shadow-md ${button_type()} ${className}`}>
            {label}
        </motion.div>
    );
}