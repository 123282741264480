import {motion} from 'framer-motion';
import Backdrop from './Backdrop';
import PlayerBubble from '../player/PlayerBubble';
import {GAME_STATUS_IN_LOBBY, GAME_STATUS_STARTED} from '../../constants/StatusConstants';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

/**
 * My Ongoing Games Modal Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function MyOngoingGamesModal(props) {

    // destructured props
    const {myOngoingGames, setMyOngoingGames} = props;

    // react router navigator
    const navigate = useNavigate();

    // join the game
    const joinGame = (game) => {
        if (game.status === GAME_STATUS_IN_LOBBY) {
            navigate(`/lobby/${game.id}`);
            return;
        }
        if (game.status === GAME_STATUS_STARTED) {
            navigate(`/game/${game.id}`, {state: {tiles: myOngoingGames.tiles}});
            return;
        }
        toast.error("Cannot join the game");
    };

    // leave the game
    const leaveGame = (gameId) => {
        window.socket.emit("leave_game", gameId, (responses) => {
            toast.success(responses.message);
            setMyOngoingGames({open: false});
        });
    };

    return (
        <Backdrop onClick={() => setMyOngoingGames({open: false})}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="flex items-center justify-center z-50 max-w-lg"
                initial={{scale: 0, rotate: 10}}
                animate={{scale: 1, rotate: 0}}
                transition={{
                    type: 'spring',
                    stiffness: 1000,
                    damping: 20
                }}
            >

                <div className="relative">
                    <div
                        className="tile bg-white rounded-md p-6 flex flex-col items-center justify-between text-light-gray text-center gap-4 relative z-20 shadow-md">

                        <img src="/images/icons/ongoing_game.png" alt="card" className="w-16 h-16"/>

                        <div className="flex flex-col gap-2 text-primary text-left">

                            <table className="table-auto text-sm">
                                <thead>
                                <tr>
                                    <th className="p-2">Game ID</th>
                                    <th className="p-2">Players</th>
                                    <th className="p-2">Status</th>
                                    <th className="p-2"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    myOngoingGames &&
                                    myOngoingGames?.games &&
                                    myOngoingGames?.games?.map((game) => (
                                        <tr key={game.id} className="text-xs text-secondary">
                                            <td className="px-2">{game.id}</td>
                                            <td className="flex flex-row flex-wrap gap-1 px-2">
                                                {
                                                    game.players.map((player) => (
                                                        <PlayerBubble
                                                            key={player.id}
                                                            color={player.color}
                                                        />
                                                    ))
                                                }
                                            </td>
                                            <td className="px-2">{game.status}</td>
                                            <td className="px-2 flex flex-row gap-3">
                                                <button className="underline text-secondary"
                                                        onClick={() => joinGame(game)}>Join
                                                </button>

                                                <button className="underline text-secondary"
                                                        onClick={() => leaveGame(game.id)}>
                                                    {
                                                        game.isHost ? 'Abandon' : 'Leave'
                                                    }
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>

            </motion.div>
        </Backdrop>
    );
};