const CARD_TYPE_GET_OUT_OF_JAIL = 'get_out_of_jail';
const CARD_TYPE_REDUCE_INTEREST = 'reduce_interest';
const CARD_TYPE_CHEST = 'chest';
const CARD_TYPE_CHANCE = 'chance';

export {
    CARD_TYPE_GET_OUT_OF_JAIL,
    CARD_TYPE_REDUCE_INTEREST,
    CARD_TYPE_CHEST,
    CARD_TYPE_CHANCE
};