/**
 * Player Container Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PlayerContainer(props) {

    // destructured props
    const {children} = props;

    return (
        <div className="players-container flex flex-col gap-4">
            {children}
        </div>
    );
}
