import {motion} from 'framer-motion';
import Backdrop from './Backdrop';
import Button from '../buttons/Button';

/**
 * Card Modal Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PayableModal(props) {

    // destructured props
    const {gameObject, setShowPayableModal, payPayableAmount} = props;

    // amount to be raised
    const amountToBeRaised = () => {
        return gameObject?.currentPlayer?.payable?.amount - gameObject?.currentPlayer?.bank || 0;
    };

    // amount to be paid
    const amountToBePaid = () => {
        return gameObject?.currentPlayer?.payable?.amount || 0;
    };

    // determines if the amount can be paid. if the player has enough in the bank
    const canPayAmount = () => {
        return gameObject?.currentPlayer?.bank >= gameObject?.currentPlayer?.payable?.amount;
    };

    return (
        <Backdrop onClick={() => setShowPayableModal(false)}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="flex items-center justify-center z-50 max-w-xs"
                initial={{scale: 0, rotate: 10}}
                animate={{scale: 1, rotate: 0}}
                transition={{
                    type: 'spring',
                    stiffness: 1000,
                    damping: 20
                }}
            >

                <div className="relative">
                    <div
                        className="tile bg-white rounded-md p-6 flex flex-col items-center justify-between text-light-gray text-center gap-4 relative z-20 shadow-md">

                        <img src="/images/icons/raise_money.png" alt="raise_money" className="w-16 h-16"/>

                        {
                            !canPayAmount() &&
                            <span className="text-secondary text-xl">You need to raise MVR {amountToBeRaised()}; Total of MVR {amountToBePaid()}</span>
                        }

                        {
                            canPayAmount() &&
                            <div className="flex flex-col items-center justify-center gap-4 mt-2">
                                <span
                                    className="text-secondary text-xl">You have an outstanding amount of MVR {amountToBePaid()}</span>
                                <Button
                                    label={`Pay MVR ${amountToBePaid()}`}
                                    onClick={payPayableAmount}
                                />
                            </div>
                        }

                    </div>
                </div>

            </motion.div>
        </Backdrop>
    );
};