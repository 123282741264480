import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBuilding, faHouse} from '@fortawesome/free-solid-svg-icons';

/**
 * Property Stage Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PropertyStage(props) {

    // destructured props
    const {property} = props;

    return (
        <div className="flex flex-row gap-0.5 items-center justify-center">

            {
                property?.stage === 1 ?
                    <FontAwesomeIcon icon={faHouse} fontSize={8}/> :
                    property?.stage === 2 ?
                        <>
                            <FontAwesomeIcon icon={faHouse} fontSize={8}/>
                            <FontAwesomeIcon icon={faHouse} fontSize={8}/>
                        </> :
                        property?.stage === 3 ?
                            <>
                                <FontAwesomeIcon icon={faHouse} fontSize={8}/>
                                <FontAwesomeIcon icon={faHouse} fontSize={8}/>
                                <FontAwesomeIcon icon={faHouse} fontSize={8}/>
                            </> :
                            property?.stage === 4 ?
                                <>
                                    <FontAwesomeIcon icon={faHouse} fontSize={8}/>
                                    <FontAwesomeIcon icon={faHouse} fontSize={8}/>
                                    <FontAwesomeIcon icon={faHouse} fontSize={8}/>
                                    <FontAwesomeIcon icon={faHouse} fontSize={8}/>
                                </> :
                                property?.stage === 5 ?
                                    <FontAwesomeIcon icon={faBuilding} fontSize={8}/> :
                                    null
            }

        </div>
    );
};