import {io} from 'socket.io-client';
import {generateOrGetPlayerId} from './helpers/ApplicationHelpers';

// configure and setup websockets
const socket = io(`${process.env.REACT_APP_SERVER_HOST}`, {transports: ['websocket']});

// on connect, emit player identifier initialization
socket.on('connect', () => {
    // on initial load, create or send a custom unique to the player
    window.socket.emit("player_identifier", {player_id: generateOrGetPlayerId()}, (responses) => {
        window.socket.player_id = responses.player_id;
    });
});

window.socket = socket;