/**
 * Player Bubble Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PlayerBubble(props) {

    // destructured props
    const {color, className} = props;

    return (
        <div className={`player w-4 h-4 rounded-full shadow-sm relative flex items-center justify-center ${className}`}
             style={{backgroundColor: color}}>
            <div className="player-glow absolute top-1 left-1"/>
        </div>
    );
}
