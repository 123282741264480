/**
 * Event Container Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function EventContainer(props) {

    // destructured props
    const {children} = props;

    return (
        <div className="events-container scrollbar flex flex-col gap-2 p-2 bg-white rounded-md overflow-y-auto">
            {children}
        </div>
    );
}
