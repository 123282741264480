import {motion} from 'framer-motion';
import Backdrop from './Backdrop';

/**
 * Player Bankrupt Modal Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PlayerBankruptModal(props) {

    // destructured props
    const {bankruptcy, setBankruptcy} = props;

    return (
        <Backdrop onClick={() => setBankruptcy({...bankruptcy, open: false})}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="flex items-center justify-center z-50 max-w-xs"
                initial={{scale: 0, rotate: 10}}
                animate={{scale: 1, rotate: 0}}
                transition={{
                    type: 'spring',
                    stiffness: 1000,
                    damping: 20
                }}
            >

                <div className="relative">
                    <div
                        className="tile bg-white rounded-md p-6 flex flex-col items-center justify-between text-light-gray text-center gap-4 relative z-20 shadow-md">

                        <img src="/images/icons/bankrupt.png" alt="card" className="w-16 h-16"/>

                        <span className="text-secondary text-2xl">{bankruptcy?.message}</span>

                    </div>
                </div>

            </motion.div>
        </Backdrop>
    );
};