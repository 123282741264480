import {isInteger} from '../../helpers/ApplicationHelpers';
import {
    TRADE_PLAYER_DEALER,
    TRADE_PLAYER_TRADER,
    TRADE_TYPE_COUNTER_OFFER,
    TRADE_TYPE_OFFER
} from '../../constants/TradeConstants';
import Backdrop from './Backdrop';
import {motion} from 'framer-motion';
import Button from '../buttons/Button';
import Input from '../inputs/Input';

/**
 * Trade Modal Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function TradeModal(props) {

    //destructured props
    const {trade, setTrade, gameObject, makeTradeOffer, confirmTrade, rejectTradeOffer} = props;

    // sets the trade amount
    const setTradeAmount = (event, type = TRADE_PLAYER_TRADER) => {
        if (trade.type === TRADE_TYPE_OFFER) return null;
        const amount = isInteger(event.target.value);
        setTrade({
            ...trade,
            [type]: {
                ...trade[type],
                amount: amount
            }
        });
    };

    // get properties depending on the type
    const getProperties = (type = TRADE_PLAYER_TRADER) => {
        const player_id = trade[type]?.playerObject?.id;
        return gameObject?.players?.find((player) => player.id === player_id)?.properties?.filter((player_prop) => player_prop.id !== trade[type].properties.find((types_prop) => player_prop.id === types_prop.id)?.id) || [];
    };

    // add property to trader on click
    const addPropertyToTrade = (property, type = TRADE_PLAYER_TRADER) => {
        if (trade.type === TRADE_TYPE_OFFER) return null;
        const property_exists = trade[type].properties.find((prop) => prop.id === property.id);
        if (property_exists) {
            return;
        }
        setTrade({
            ...trade,
            [type]: {
                ...trade[type],
                properties: [...trade[type].properties, property]
            }
        });
    };

    // remove the property from trader properties
    const removePropertyFromTrade = (property, type = TRADE_PLAYER_TRADER) => {
        if (trade.type === TRADE_TYPE_OFFER) return null;
        const filtered_properties = trade[type].properties.filter((prop) => prop.id !== property.id);
        setTrade({
            ...trade,
            [type]: {
                ...trade[type],
                properties: filtered_properties
            }
        });
    };

    // handle what happens when counter offer is clicked. trader and dealer will be exchanged
    const handleCounterOfferClick = () => {
        const trader = trade.dealer;
        const dealer = trade.trader;
        setTrade({
            ...trade,
            trader: trader,
            dealer: dealer,
            type: TRADE_TYPE_COUNTER_OFFER
        });
    };

    // cancel trade
    const cancelTrade = () => {
        setTrade({...trade, showModal: false});
        if (trade.type === TRADE_TYPE_COUNTER_OFFER) {
            rejectTradeOffer();
        }
    };

    return (
        <Backdrop onClick={null}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl sm:p-6"
                initial={{scale: 0, rotate: 10}}
                animate={{scale: 1, rotate: 0}}
                transition={{
                    type: 'spring',
                    stiffness: 1000,
                    damping: 20
                }}
            >
                <div className="flex flex-row justify-between gap-2">

                    {/*trader*/}
                    <div className="flex flex-col gap-2 w-full">
                        <div className="self-center flex flex-col items-center justify-center gap-1">
                            <span
                                className="text-md text-center p-2 rounded-full text-white"
                                style={{backgroundColor: trade?.trader?.playerObject?.color}}>{trade?.trader?.playerObject?.name}</span>
                        </div>

                        <div
                            className="px-2 py-3 rounded-md border-2 border-dashed flex flex-col gap-2">
                            <div className="flex flex-row gap-1 items-center justify-center text-primary">
                                <span className="text-xs">MVR</span>
                                <Input
                                    onChange={(event) => setTradeAmount(event)}
                                    value={trade?.trader?.amount}
                                    className="!self-center text-xs !p-2 !w-12 text-center"
                                />
                            </div>

                            <div className="property_cards container flex flex-col gap-1">
                                {
                                    trade.trader.properties.map((property) => (
                                        <div key={`trader_${property.id}`}
                                             className="property_card px-2 pt-3 pb-2 text-primary relative border cursor-pointer"
                                             onClick={() => removePropertyFromTrade(property)}>
                                            <div className="absolute top-0 left-0 w-full h-2"
                                                 style={{backgroundColor: trade?.trader?.playerObject?.color}}/>
                                            <div
                                                className="flex flex-col gap-1 items-center justify-center">
                                                <span className="text-xs">{property.name}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div
                            className="px-2 py-3 rounded-md border-2 border-dashed flex flex-col gap-2">
                            {
                                getProperties().map((property) => (
                                    <div key={`trader_${property.id}`}
                                         className="property_card px-2 pt-3 pb-2 text-primary relative border cursor-pointer"
                                         onClick={() => addPropertyToTrade(property)}>
                                        <div className="absolute top-0 left-0 w-full h-2"
                                             style={{backgroundColor: trade?.trader?.playerObject?.color}}/>
                                        <div
                                            className="flex flex-col gap-1 items-center justify-center">
                                            <span className="text-xs">{property.name}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    {/*dealer*/}
                    <div className="flex flex-col w-full gap-2">
                        <div className="self-center flex flex-col items-center justify-center gap-1">
                            <span
                                className="text-md text-center p-2 rounded-full text-white"
                                style={{backgroundColor: trade?.dealer?.playerObject?.color}}>{trade?.dealer?.playerObject?.name}</span>
                        </div>

                        <div
                            className="px-2 py-3 rounded-md border-2 border-dashed flex flex-col gap-2">
                            <div className="flex flex-row gap-1 items-center justify-center text-primary">
                                <span className="text-xs">MVR</span>
                                <Input
                                    onChange={(event) => setTradeAmount(event, TRADE_PLAYER_DEALER)}
                                    value={trade?.dealer?.amount}
                                    className="!self-center text-xs !p-2 !w-12 text-center"
                                />
                            </div>

                            <div className="property_cards container flex flex-col gap-1">
                                {
                                    trade.dealer.properties.map((property) => (
                                        <div key={`dealer_${property.id}`}
                                             className="property_card text-primary px-2 pt-3 pb-2 relative border cursor-pointer"
                                             onClick={() => removePropertyFromTrade(property, TRADE_PLAYER_DEALER)}>
                                            <div className="absolute top-0 left-0 w-full h-2"
                                                 style={{backgroundColor: trade?.dealer?.playerObject?.color}}/>
                                            <div
                                                className="flex flex-col gap-1 items-center justify-center">
                                                <span className="text-xs">{property.name}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div
                            className="px-2 py-3 rounded-md border-2 border-dashed flex flex-col gap-2">
                            {
                                getProperties(TRADE_PLAYER_DEALER).map((property) => (
                                    <div key={`dealer_${property.id}`}
                                         className="property_card text-primary px-2 pt-3 pb-2 relative border cursor-pointer"
                                         onClick={() => addPropertyToTrade(property, TRADE_PLAYER_DEALER)}>
                                        <div className="absolute top-0 left-0 w-full h-2"
                                             style={{backgroundColor: trade?.dealer?.playerObject?.color}}/>
                                        <div
                                            className="flex flex-col gap-1 items-center justify-center">
                                            <span className="text-xs">{property.name}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                </div>
                <div className="mt-5 sm:mt-6 flex flex-col gap-2">
                    {
                        trade.type === TRADE_TYPE_OFFER ?
                            <div className="flex flex-row gap-4 justify-between">
                                <Button
                                    label="Trade"
                                    onClick={confirmTrade}
                                />

                                <Button
                                    label="Counter Offer"
                                    onClick={handleCounterOfferClick}
                                />

                                <Button
                                    label="Reject"
                                    type="danger"
                                    onClick={rejectTradeOffer}
                                />
                            </div> : null
                    }
                    {
                        !trade.type || trade.type === TRADE_TYPE_COUNTER_OFFER ?
                            <div className="flex flex-row gap-2 justify-between">
                                <Button
                                    label="Make Offer"
                                    onClick={makeTradeOffer}
                                />

                                <Button
                                    label="Cancel"
                                    type="danger"
                                    onClick={cancelTrade}
                                />
                            </div> : null
                    }
                </div>
            </motion.div>
        </Backdrop>
    );
}
