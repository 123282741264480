/**
 * Dice face three Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function DiceFaceThree(props) {

    // destructured props
    const {} = props;

    return (
        <div
            className="flex items-center justify-center bg-white rounded-md shadow-sm border-2 border-gray-200 min-w-[60px] min-h-[60px] relative">
            <div className="rounded-full w-4 h-4 bg-transparent shadow-sm border absolute top-0 left-0"/>
            <div className="rounded-full w-4 h-4 bg-transparent shadow-sm border"/>
            <div className="rounded-full w-4 h-4 bg-transparent shadow-sm border absolute bottom-0 right-0"/>
        </div>
    );
}
