import {v4 as uuidv4} from 'uuid';
import {toast} from 'react-toastify';

/**
 * Generate or get Player ID
 *
 * @returns {*|string}
 */
export const generateOrGetPlayerId = () => {
    const player_id = localStorage.getItem('player_id');

    if (player_id === null || player_id === '' || !player_id) {
        const uuid = uuidv4();
        localStorage.setItem('player_id', uuid);
        return uuid;
    }
    return player_id;
};

/**
 * Checks if the value is an integer
 *
 * @param value
 * @returns {boolean|null}
 */
export const isInteger = (value) => {
    value = parseInt(value);
    const is_integer = Number.isInteger(value);
    if (!is_integer) {
        toast('The number must be an integer value', {
            type: 'error'
        });
        return null;
    }
    return value;
};