import {motion} from 'framer-motion';
import Backdrop from './Backdrop';
import Button from '../buttons/Button';
import PropertyDetailCard from '../property/PropertyDetailCard';

/**
 * Auction Modal Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function AuctionModal(props) {

    // destructured props
    const {gameObject, bidAuction} = props;

    // gets the bid amount
    const getBidAmount = () => {
        return !gameObject?.auction?.amount ? 20 : gameObject?.auction?.amount + 20;
    };

    // can the player bid
    const playerCanBid = (amount) => {
        const current_player_from_game_object = gameObject?.players?.find((player) => player.id === window.socket.player_id);
        return gameObject?.auction?.player_id !== window.socket.player_id && current_player_from_game_object?.bank > amount;

    };

    return (
        <Backdrop onClick={null}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="flex items-center justify-center z-50 max-w-xs"
                initial={{scale: 0, rotate: 10}}
                animate={{scale: 1, rotate: 0}}
                transition={{
                    type: 'spring',
                    stiffness: 1000,
                    damping: 20
                }}
            >

                {
                    gameObject?.auction?.property &&
                    <PropertyDetailCard property={gameObject.auction.property}>

                        <Button
                            label={`Bid MVR ${getBidAmount()}`}
                            disabled={!playerCanBid(getBidAmount())}
                            onClick={() => bidAuction(getBidAmount())}
                        />

                        <div className="players flex flex-row gap-2 mt-4">

                            {
                                gameObject?.players?.length > 0 ?
                                    gameObject?.players?.map((player) => (
                                        <div
                                            key={player.id}
                                            className="relative w-24 h-auto">
                                            <div
                                                className="tile bg-white rounded-md px-4 py-2 flex flex-col items-center justify-center text-light-gray font-bold text-center gap-1 w-24 h-auto relative z-20 shadow-md">
                                                <span className="text-xl text-light-gray">{player.name}</span>
                                            </div>
                                            <div className="absolute top-0 left-0 h-full rounded-md mt-1 w-full z-10"
                                                 style={{backgroundColor: player.color}}/>

                                            {
                                                player.id === gameObject?.auction?.player_id && gameObject?.auction?.amount > 0 ?
                                                    <div
                                                        className="absolute px-2 py-1 text-xs bg-gray-600 text-white top-0 -mt-6 rounded-full">{gameObject?.auction?.amount}</div> : null
                                            }
                                        </div>
                                    )) : null
                            }

                        </div>

                    </PropertyDetailCard>
                }

            </motion.div>
        </Backdrop>
    );
};