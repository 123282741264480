const TILE_TYPE_GO = 'start';
const TILE_TYPE_PROPERTY = 'property';
const TILE_TYPE_CHEST = 'chest';
const TILE_TYPE_INCOME_TAX = 'tax';
const TILE_TYPE_STATION = 'station';
const TILE_TYPE_CHANCE = 'chance';
const TILE_TYPE_JAIL = 'jail';
const TILE_TYPE_UTIL = 'util';
const TILE_TYPE_PARKING = 'parking';
const TILE_TYPE_GO_TO_JAIL = 'go_to_jail';

export {
    TILE_TYPE_GO,
    TILE_TYPE_PROPERTY,
    TILE_TYPE_CHEST,
    TILE_TYPE_INCOME_TAX,
    TILE_TYPE_STATION,
    TILE_TYPE_CHANCE,
    TILE_TYPE_JAIL,
    TILE_TYPE_UTIL,
    TILE_TYPE_PARKING,
    TILE_TYPE_GO_TO_JAIL
};