import {
    TILE_TYPE_CHANCE,
    TILE_TYPE_CHEST,
    TILE_TYPE_GO,
    TILE_TYPE_GO_TO_JAIL,
    TILE_TYPE_INCOME_TAX,
    TILE_TYPE_JAIL,
    TILE_TYPE_PARKING,
    TILE_TYPE_PROPERTY,
    TILE_TYPE_STATION,
    TILE_TYPE_UTIL
} from '../../constants/TileConstants';
import GoTile from './GoTile';
import PropertyTile from './PropertyTile';
import CommunityChestTile from './CommunityChestTile';
import IncomeTaxTile from './IncomeTaxTile';
import StationTile from './StationTile';
import ChanceTile from './ChanceTile';
import JailTile from './JailTile';
import UtilTile from './UtilTile';
import ParkingTile from './ParkingTile';
import GoToJailTile from './GoToJailTile';
import {useState} from 'react';
import TileDetailModal from '../modal/TileDetailModal';
import {AnimatePresence, motion} from 'framer-motion';
import PlayerBubble from '../player/PlayerBubble';
import PlayerBubbleContainer from '../player/PlayerBubbleContainer';
import {findPropertyFromPlayers, isPropertyMortgaged} from '../../helpers/GameHelpers';
import {INTERACTION_TYPE_TRADE} from '../../constants/InteractionConstants';

/**
 * Tiles Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Tiles(props) {

    // destructured props
    const {tiles, players, handlePropertyClick, interacting = false} = props;

    // states
    const [showTileDetails, setShowTileDetails] = useState({tile: null, open: false});

    // renders player positions
    const PlayersPositions = ({tile}) => {
        return (
            <PlayerBubbleContainer>
                {
                    players.map((player) => (
                        player.currentPosition === tile.id &&
                        <PlayerBubble
                            key={player.id}
                            color={player.color}
                        />
                    ))
                }
            </PlayerBubbleContainer>
        );
    };

    // tile component
    const TileComponent = ({tile, onClick, players, interacting}) => {
        const property = findPropertyFromPlayers(players, tile);
        switch (tile.type) {
            case TILE_TYPE_GO:
                return <GoTile
                    tile={tile}
                    renderPlayersPositions={<PlayersPositions tile={tile}/>}
                />;
            case TILE_TYPE_PROPERTY:
                return <PropertyTile
                    tile={tile}
                    onClick={onClick}
                    interacting={interacting}
                    property={property}
                    isMortgaged={isPropertyMortgaged(players, tile)}
                    renderPlayersPositions={<PlayersPositions tile={tile}/>}
                />;
            case TILE_TYPE_CHEST:
                return <CommunityChestTile
                    tile={tile}
                    renderPlayersPositions={<PlayersPositions tile={tile}/>}
                />;
            case TILE_TYPE_INCOME_TAX:
                return <IncomeTaxTile
                    tile={tile}
                    renderPlayersPositions={<PlayersPositions tile={tile}/>}
                />;
            case TILE_TYPE_STATION:
                return <StationTile
                    tile={tile}
                    onClick={onClick}
                    interacting={interacting}
                    property={property}
                    isMortgaged={isPropertyMortgaged(players, tile)}
                    renderPlayersPositions={<PlayersPositions tile={tile}/>}
                />;
            case TILE_TYPE_CHANCE:
                return <ChanceTile
                    tile={tile}
                    renderPlayersPositions={<PlayersPositions tile={tile}/>}
                />;
            case TILE_TYPE_JAIL:
                return <JailTile
                    tile={tile}
                    renderPlayersPositions={<PlayersPositions tile={tile}/>}
                />;
            case TILE_TYPE_UTIL:
                return <UtilTile
                    tile={tile}
                    onClick={onClick}
                    interacting={interacting}
                    property={property}
                    isMortgaged={isPropertyMortgaged(players, tile)}
                    renderPlayersPositions={<PlayersPositions tile={tile}/>}
                />;
            case TILE_TYPE_PARKING:
                return <ParkingTile
                    tile={tile}
                    renderPlayersPositions={<PlayersPositions tile={tile}/>}
                />;
            case TILE_TYPE_GO_TO_JAIL:
                return <GoToJailTile
                    tile={tile}
                    renderPlayersPositions={<PlayersPositions tile={tile}/>}
                />;
            default:
                return null;
        }
    };

    // handle card click
    const handleCardClick = (tile) => {
        if (![TILE_TYPE_PROPERTY, TILE_TYPE_STATION, TILE_TYPE_UTIL].includes(tile.type)) {
            return;
        }
        // if interaction state is switched on, then follow the interaction type
        if (interacting.state) {
            const property = findPropertyFromPlayers(players, tile);
            handlePropertyClick(property?.property);
            return;
        }
        setShowTileDetails({tile: tile, open: true});
    };

    return (
        <div className="tiles flex flex-row gap-6 gap-y-12 flex-wrap justify-center">

            {
                tiles.map((tile, index) => (
                    <motion.div
                        key={tile.id}
                        initial={{scale: 0}}
                        animate={{scale: 1}}
                        transition={{
                            type: "spring",
                            stiffness: 1000,
                            damping: 20,
                            delay: 0.1 * index
                        }}
                    >
                        <TileComponent
                            tile={tile}
                            onClick={() => handleCardClick(tile)}
                            players={players}
                            interacting={interacting.state && interacting.interaction !== INTERACTION_TYPE_TRADE}
                        />
                    </motion.div>
                ))
            }

            <AnimatePresence
                initial={false}
                mode="wait"
                onExitComplete={() => null}
            >
                {
                    showTileDetails.open &&
                    <TileDetailModal
                        handleClose={setShowTileDetails}
                        tile={showTileDetails}
                    />
                }
            </AnimatePresence>

        </div>
    );
}