import {AnimatePresence, motion} from 'framer-motion';

/**
 * Player Actions Component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PlayerActions(props) {

    // destructured props
    const {children, visible = false} = props;

    return (
        <AnimatePresence>
            {
                visible && (
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1, width: "auto"}}
                        exit={{opacity: 0}}
                        className="z-40">
                        <div className="fixed bottom-0 mx-auto left-0 right-0 flex items-center justify-center">
                            <div
                                className="flex flex-row items-center justify-center bg-gray-200 rounded-t-lg pb-4 shadow-md">
                                {children}
                            </div>
                        </div>
                    </motion.div>
                )
            }
        </AnimatePresence>
    );
}